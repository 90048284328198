.terms-container {
    /* max-width: 900px; */
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.terms-container h1 {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.terms-content {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
}

.terms-content p {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .terms-container {
        padding: 15px;
    }

    .terms-container h1 {
        font-size: 2rem;
    }

    .terms-content {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .terms-container {
        padding: 10px;
    }

    .terms-container h1 {
        font-size: 1.8rem;
    }

    .terms-content {
        font-size: 0.85rem;
    }
}