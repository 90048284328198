/* Unique class for centering the form on the page */
.centered-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    background-color: #ffffff;
  }
  
  /* Form container styling */
  .signup-login-form {
    width: 400px;
    padding: 30px;
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  /* Form header styling */
  .signup-login-header {
    text-align: center;
    color: #000000;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  /* Input fields styling */
  .signup-login-input {
    padding: 10px;
    border-radius: 8px;
    font-size: 16px;
    border: 1px solid #d9d9d9;
    transition: border-color 0.3s ease;
  }
  
  .signup-login-input:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  
  /* Button styling */
  .signup-login-button {
    width: 100%;
    background: linear-gradient(45deg, #1890ff, #40a9ff);
    border: none;
    font-size: 16px;
    height: 40px;
    border-radius: 20px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .signup-login-button:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
  
  /* Link button styling */
  .signup-login-link {
    text-align: center!important;
    margin-top: 10px;
    color: #1890ff;
    font-size: 14px;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .signup-login-link:hover {
    color: #40a9ff;
  }

  

  .success-container {
    text-align: center;
    padding: 50px;
  }
/*   
  .checkout-form {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .billing-section, .payment-section {
    width: 38%;
  }
  
  .section-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .card-element {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #6c63ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:disabled {
    background-color: #aaa;
  }
   */
   .checkout-form {
    display: flex;
    flex-direction: column; /* Stack sections vertically on smaller screens */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .billing-section, .payment-section {
    width: 100%; /* Full width on small screens */
    margin-bottom: 20px; /* Space between sections */
  }
  
  .section-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .input-field {
    width: 100%; /* Full width for input fields */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .card-element {
    width: 100%; /* Full width for card element */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #6c63ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:disabled {
    background-color: #aaa;
  }
  
  /* Media Queries for Responsiveness */
  @media (min-width: 600px) {
    .checkout-form {
      flex-direction: row; /* Display sections side-by-side on larger screens */
      justify-content: space-between;
    }
  
    .billing-section, .payment-section {
      width: 47%; /* Adjust width for larger screens */
      margin-bottom: 0; /* Remove bottom margin */
    }
  
    .billing-section {
      margin-right: 4%; /* Add some space between sections */
    }
  }
  
  @media (min-width: 1024px) {
    .checkout-form {
      padding: 40px; /* Increase padding on larger screens */
    }
    
    .section-title {
      font-size: 22px; /* Larger title font size on bigger screens */
    }
    
    .input-field, .card-element {
      font-size: 16px; /* Larger text inside input fields */
    }
  }
  @media (max-width: 768px) {
    .centered-form-container {
        flex-direction: column!important;
    }
}