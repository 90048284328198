/* .layout {
    background: #fff;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #852c91 0%, #e87c90 100%);
  }
  
  .logo {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
  }
  
  .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
  }
  
  .menu-item {
    color: #000000 !important;
    font-size: 18px;
  } */
.layout {
  background: #fff;
 
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #852c91 0%, #e87c90 100%);
  padding: 0 24px;
}

.logo {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent;

}

.menu-item {
  color: #000000 !important;
  font-size: 18px;
}

.mobileMenuButton {
  display: none;
}

.desktopMenu {
  display: flex;
}

.mobileDrawer {
  display: flex;

  flex-direction: column;
}
.btnMenu{
  color: #ffffff;
  background-color: inherit;
  border: none!important;
}
.btnMenu:hover{
  color: #ffffff!important;
  background-color: inherit!important;
}


.tabs {
  display: flex;
  justify-content: flex-start; /* Align tabs to the start */
  margin: 20px 0;
  position: relative;
  border-bottom: 4px solid #0d728f; /* Bottom border as seen in the image */
}

.tab {
  padding: 10px 20px; /* Add some padding */
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px; /* Font size similar to the image */
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tab.active {
  background-color: #ec3d72; /* Background color for the active tab */
  color: #fff;
  border: none; /* Remove border for active tab */
  border-bottom: 4px solid transparent; /* Avoid double border effect */
  position: relative;
  z-index: 2; /* Bring to the front */
}

.tab:not(.active) {
  background-color: #f5f8fb; /* Light gray background for inactive tabs */
  color: #333; /* Darker text for inactive tabs */
}

.tabs::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: #0d728f; /* Blue bottom border as seen in the image */
  z-index: 1;
}

.title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.plans-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.plan-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 23%;
  height: 270px;
  text-align: left;
  position: relative;
  margin: 10px;
}

.plan-card h3 {
  font-size: 18px;
  /* margin-bottom: 10px; */
  color: #fff;
  background: #ec3d72;
  padding: 10px;
  border-radius: 10px 10px 0 0;
}

.plan-card h4 {
  font-size: 14px;
  /* margin-top: 20px; */
  color: #ffffff;
  font-weight: bold;
  text-align: right;
  position: absolute;
  top: 15px;
  right: 10px;
}

.plan-card ul {
  list-style: none;
  padding-left: 2px;
  line-height: 18px;
  font-size: 12px; /* Adjust font size as needed */
}

.plan-card li {
  margin-bottom: 10px;
  color: #333;
  display: flex;
  align-items: center;
}

.plan-card li::before {
  content: "\25B3"; /* Unicode character for an upward triangle */
  color: #ff4d4f;   /* Color of the icon */
  font-size: 16px;  /* Size of the icon */
  margin-right: 8px; /* Space between the icon and the text */
  font-weight: bold;
}
.subscribe-button {
  width: 100%;
  background: linear-gradient(90deg, #852c91 0%, #e87c90 100%);
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
  position: absolute;
  bottom: 0px;
}

.subscribe-button:hover {
  background: linear-gradient(90deg, #e87c90 0%, #852c91 100%)!important;
}

/* Plan specific styles */
.plan-card.gold {
  /* border-top: 5px solid #e87c90; */
}

.plan-card.free h3 {

  background: #5d338b;
}

.site-layout-content {
  padding: 24px;
  background: #fff;
}
@media (max-width: 1200px) {
  .plan-card {
    width: 290px!important;
  }
}
@media (max-width: 768px) {
  .desktopMenu {
    display: none;
  }
  .plan-card h3 {
    font-size: 13px!important;
  }
  .plan-card h4 {
    font-size: 12px!important;
  }

  .mobileMenuButton {
    display: block;
  }

  .menu {
    flex-direction: column;
  }
  .menu-item.ant-menu-item-selected {
    color: #fc0060 !important;
    background-color: #ffffff!important;
  }
  .plan-card {
    width: 320px!important;
    height: 330px;
  }

  /* Remove any background highlight */
  .menu-item.ant-menu-item-selected::after {
    border-right: none !important;
    background-color: #ffffff!important;
  }
}