.footer {
    background-color: #1a1c20;
    color: #fff;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer-links {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .footer-links a {
    color: #fff;
    margin: 0 15px;
    text-decoration: none;
    font-size: 16px;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .footer-copyright {
    font-size: 14px;
    color: #bbb;
  }
  
  .footer-copyright a {
    color: #bbb;
    text-decoration: none;
  }
  
  .footer-copyright a:hover {
    text-decoration: underline;
  }
  .privacy-container {
    max-width: 800px;
    text-align: justify;
    margin: 20px auto;
    padding: 20px;
    line-height: 1.6;
    font-size: 16px;
    color: #333;
  }
  
  .privacy-container p {
    margin-bottom: 20px;
  }
  
  .privacy-container strong {
    font-weight: bold;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-links a {
      display: block;
      margin: 5px 0;
    }
  
    .footer {
      padding: 10px;
    }
  }
  